import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Accounts from "./Pages/accounts/Accounts";
import DashBoard from "./Pages/dashboard/DashBoard";
import MemberPage from "./Pages/members/MemberPage";
import MembersList from "./Pages/members/MembersList";
import NewMember from "./Pages/members/NewMember";
import Settings from "./Pages/settings/Settings";
import api from "./api/api";
import Login from "./auth/Login";
import Construction from "./components/Construction/Construction";

import ResetPassword from "./auth/ResetPassword";
import { useAuth } from "./context/AuthContext";
import Loading from "./widgets/loading/Loading";


function App() {

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 1000);

    return () => clearTimeout(timer); // This will clear the timeout if the component is unmounted before the timeout finishes
  }, []);

  const { isAuthenticated, userData } = useAuth();

  const { data: members = [], isLoading: loading } = useQuery({
    queryKey: ['membersWithDetails'],
    queryFn: async () => {
      const response = await api.get("/membersWithDetails");
      return response.data;
    }
  });


  return (
    <div className="App">
      {loading ? (
        <Loading />
      ) : (
        <Routes>
          {!isAuthenticated && (
            <Route
              element={!isAuthenticated ? <Login /> : <Navigate to="/" />}
              path="/login"
            />
          )}

          <Route path="/reset-password/:token" element={<ResetPassword />} />

          <Route path="/" element={isAuthenticated ? <DashBoard /> : <Login />} />

          <Route
            path="/members"
            element={
              isAuthenticated ? <MembersList members={members} loading={loading} /> : <Login />
            }
          />
          <Route
            path="/members/add"
            element={
              isAuthenticated ? (
                <NewMember members={members} />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/members/:id"
            element={
              isAuthenticated ? (
                <MemberPage members={members} loading={loading} />
              ) : (
                <Login />
              )
            }
          />
          {isLoaded && userData && userData.position !== "tl" && (
            <>
              <Route
                path="/accounts"
                element={isAuthenticated ? <Accounts /> : <Login />}
              />
              <Route
                path="/settings"
                element={isAuthenticated ? <Settings /> : <Login />}
              />
            </>
          )}
          <Route path="*" exact={true} element={<Construction />} />
        </Routes>
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
