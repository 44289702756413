import React, { useState } from 'react';
import SalesCard from '../../components/card/salesCard/SalesCard';
import Construction from '../../components/Construction/Construction';
import DsSales from '../../components/forms/dsSales/DsSales';
import DsSummaryForm from '../../components/forms/dsSales/dsSummaryForm/DsSummaryForm';
import DefaultLayout from '../../layout/DefaultLayout';

const Accounts = () => {
  const [selectBtn, setSelectedBtn] = useState(null);

  const renderComponents = () => {
    switch (selectBtn) {
      case 'dsSales':
        return <DsSales />;
      case 'dsExpense':
        return (
          <>
            <button
              className="w-20 h-8 border-2"
              onClick={() => setSelectedBtn(null)}
            >
              Close
            </button>
            <Construction message="Under Construction 🦺" />
          </>
        );
      case 'dsView':
        return (
          <>
            <DsSummaryForm close={setSelectedBtn} />
          </>
        );
      case 'scSales':
        return (
          <>
            <h1>This is the DS Sales Forms</h1>
            <button onClick={() => setSelectedBtn(null)}>Close</button>
          </>
        );
      case 'scExpense':
        return (
          <>
            <h1>This is the DS Expense Forms</h1>
            <button onClick={() => setSelectedBtn(null)}>Close</button>
          </>
        );
      case 'scView':
        return (
          <>
            <h1>This is Going to be the sales Table</h1>
            <button onClick={() => setSelectedBtn(null)}>Close</button>
          </>
        );
      case 'pmSales':
        return (
          <>
            <h1>This is the DS Sales Forms</h1>
            <button onClick={() => setSelectedBtn(null)}>Close</button>
          </>
        );
      case 'pmExpense':
        return (
          <>
            <h1>This is the DS Expense Forms</h1>
            <button onClick={() => setSelectedBtn(null)}>Close</button>
          </>
        );
      case 'pmView':
        return (
          <>
            <h1>This is Going to be the sales Table</h1>
            <button onClick={() => setSelectedBtn(null)}>Close</button>
          </>
        );
      case 'companySales':
        return (
          <>
            <h1>This is the DS Sales Forms</h1>
            <button onClick={() => setSelectedBtn(null)}>Close</button>
          </>
        );
      case 'companyExpense':
        return (
          <>
            <h1>This is the DS Expense Forms</h1>
            <button onClick={() => setSelectedBtn(null)}>Close</button>
          </>
        );
      case 'companyView':
        return (
          <>
            <h1>This is Going to be the sales Table</h1>
            <button onClick={() => setSelectedBtn(null)}>Close</button>
          </>
        );
      default:
        return null;
        break;
    }
  };

  return (
    <DefaultLayout>
      {selectBtn === null && (
        <div>
          <SalesCard cardName="ds" setSelectedBtn={setSelectedBtn} />
          {/* <SalesCard cardName="sc" setSelectedBtn={setSelectedBtn} />
          <SalesCard cardName="pm" setSelectedBtn={setSelectedBtn} /> */}
        </div>
      )}
      {renderComponents()}
    </DefaultLayout>
  );
};

export default Accounts;
