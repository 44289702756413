import React from 'react';
import AddDsMember from '../../components/forms/addDsMember/AddDsMember';
import DefaultLayout from '../../layout/DefaultLayout';

const NewMember = ({ members }) => {
  console.log(typeof setMembers);
  return (
    <DefaultLayout>
      <AddDsMember members={members} />
    </DefaultLayout>
  );
};

export default NewMember;
