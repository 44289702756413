import { useState } from 'react';
import DefaultLayout from '../../layout/DefaultLayout';
import Beta from '../../widgets/alert/Beta';
import DirectSupports from './DirectSupports';
const DashBoard = () => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <DefaultLayout>
      {isOpen && <Beta close={setIsOpen} />}
      <DirectSupports />
    </DefaultLayout>
  );
};

export default DashBoard;
