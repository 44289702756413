import React from 'react';

const Input = ({ value, change, type, id, placeholder, disabled }) => {
  return (
    <label
      htmlFor={id}
      className="relative block w-[95%] mt-5 rounded-md border border-gray-200 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
    >
      <input
        value={value}
        onChange={(e) => change(e.target.value)}
        type={type}
        id={id}
        className="peer w-full border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0"
        placeholder="Username"
        step={type === 'number' ? 'any' : undefined}
        required
        disabled={disabled}
      />

      <span className="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
        {placeholder}
      </span>
    </label>
  );
};

export default Input;
