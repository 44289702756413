import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { calculateSpending } from '../../utils/fundingStatus';

const Projection = ({ planStart, planEnd, category, budget, remaining }) => {
  const {
    totalShouldHaveSpent,
    totalShouldHaveSpentPercentage,
    totalSpent,
    totalSpentPercentage,
    overspending,
    overspendingPerMonth,
    underspending,
    underspendingPerMonth,
  } = calculateSpending(planStart, planEnd, budget, remaining);

  const { userData } = useAuth();
  return (
    <>
      <div className="rounded-sm border border-stroke w-[100%] bg-white py-3 px-7.5 shadow-md">
        <div className="justify-between gap-4 sm:flex ml-4">
          <h1 className="text-lg font-bold text-slate-600">
            {/* {category === 'Assistance with social and community participation (Social, Community and Civic Participation)' ? "Social & Community Participation" 
                    : category === 'Assistance with daily life (Daily Activities)' ? 'Assistance with daily life' : 'Consumables' } */}
            {category}
          </h1>
        </div>
        <div className="justify-between gap-4 sm:flex ml-4">
          <div>
            <h5 className="text-lg font-normal text-slate-600">
              {userData.position !== 'tl'
                ? 'Budget :$' + budget.toLocaleString()
                : 'Hours :' + (budget / 65.47).toLocaleString()}
            </h5>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 p-5 text-sm">
          <div className="border p-2 rounded-md">
            <p>Projected Spending {userData.position === 'tl' && 'Hours'} </p>
            <p>
              {userData.position !== 'tl'
                ? totalShouldHaveSpent.toLocaleString()
                : (totalShouldHaveSpent / 65.47).toLocaleString()}
            </p>
          </div>
          <div className="border p-2 rounded-md">
            <p>Current Spending {userData.position === 'tl' && 'Hours'}</p>
            <p>
              {userData.position !== 'tl'
                ? '$' + totalSpent.toLocaleString()
                : (totalSpent / 65.47).toLocaleString()}
            </p>
          </div>
          <div className="border p-2 rounded-md">
            {overspendingPerMonth === 0 ? (
              <p>Under Spending P/M {userData.position === 'tl' && 'Hours'}</p>
            ) : (
              <p>Over Spending P/M {userData.position === 'tl' && 'Hours'}</p>
            )}
            {overspendingPerMonth === 0 ? (
              <p>
                {userData.position !== 'tl'
                  ? '$' + underspendingPerMonth.toLocaleString()
                  : (underspendingPerMonth / 65.47).toLocaleString()}
              </p>
            ) : (
              <p>
                {userData.position !== 'tl'
                  ? '$' + overspendingPerMonth.toLocaleString()
                  : (overspendingPerMonth / 65.47).toLocaleString()}
              </p>
            )}
          </div>
          <div className="border p-2 rounded-md">
            {overspending === 0 ? (
              <p>Under Spent {userData.position === 'tl' && 'Hours'}</p>
            ) : (
              <p>Over Spent {userData.position === 'tl' && 'Hours'}</p>
            )}
            {overspending === 0 ? (
              <p>
                {userData.position !== 'tl'
                  ? '$' + underspending.toLocaleString()
                  : (underspending / 65.47).toLocaleString()}
              </p>
            ) : (
              <p>
                {userData.position !== 'tl'
                  ? '$' + overspending.toLocaleString()
                  : (overspending / 65.47).toLocaleString()}
              </p>
            )}
          </div>
        </div>

        <div className="grid grid-rows-2">
          <div className="w-[100%] mx-auto p-5">
            <p className="pb-2">PROJECTED SPENDING</p>
            <ProgressBar
              borderRadius="0"
              completed={totalShouldHaveSpentPercentage.toFixed(1)}
              maxCompleted={100}
              bgColor="#22A699"
            />
          </div>
          <div className="w-[100%] mx-auto p-5">
            <p className="pb-2">CURRENT SPENDING</p>
            <ProgressBar
              borderRadius="0"
              completed={totalSpentPercentage.toFixed(1)}
              maxCompleted={100}
              bgColor="#FF6666"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Projection;
