import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { IoExtensionPuzzleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api';
import BreadCrumb from '../../breadCrumb/BreadCrumb';
import Modal from '../../modal/Modal';
import Input from '../formElements/inputs/Input';
import LoadingButton from '../formElements/loadingButton/LoadingButton';
import SelectInput from '../formElements/slectInput/SelectInput';
import SubmitButton from '../formElements/submitButton/SubmitButton';

const AddDsMember = () => {
  let [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [ndis, setNdis] = useState('');
  const [community, setCommunity] = useState('');

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newMember) => {
      try {
        return await api.post('/membersWithDetails', newMember);
      } catch (err) {
        // Rethrow the error so `onError` can handle it
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['membersWithDetails'] });
      toast.success('User Added Successfully', {
        position: 'bottom-left',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      setIsOpen(true);
      setName('');
      setNdis('');
      setCommunity('');
    },
    onError: (error) => {
      console.error('Error in mutation onError:', error);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newMember = { name, ndis, community };
    setLoading(true);
    try {
      await mutation.mutateAsync(newMember);
    } catch (error) {
      // If `mutation.mutateAsync` throws, it will be caught here.
      const errorMsg =
        error.response?.data?.message || 'An unexpected error occurred.';
      console.error('Caught in handleSubmit:', error);
      toast.error(errorMsg, {
        position: 'bottom-left',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } finally {
      setLoading(false);
    }
  };

  const navigation = () => {
    navigate('/members');
  };

  return (
    <>
      <BreadCrumb
        currentPage="Add Participant"
        prevPage="Members"
        icon={<IoExtensionPuzzleOutline />}
        navigation={navigation}
      />
      <div className="mt-2">
        <div className="w-full shadow-md rounded-md">
          <form action="" onSubmit={handleSubmit}>
            <div className="w-full h-14 bg-slate-200">
              <h3 className="text-xl p-4 font-bold text-slate-600">User</h3>
            </div>
            <div className="flex flex-col items-center">
              {/* Name */}
              <Input
                id="name"
                value={name}
                change={setName}
                placeholder="Full Name"
                type="text"
              />
              {/* NDIS */}
              <Input
                id="ndis"
                value={ndis}
                change={setNdis}
                placeholder="NDIS Number"
                type="text"
              />
              <SelectInput
                value={community}
                change={setCommunity}
                id="community"
                placeholder="Community"
                options={[
                  { value: 'A', name: 'A' },
                  { value: 'B', name: 'B' },
                  { value: 'C', name: 'C' },
                  { value: 'D', name: 'D' },
                ]}
              />
              {loading ? (
                <LoadingButton />
              ) : (
                <SubmitButton
                  btnName="Add Member"
                  icon={<AiOutlineArrowRight />}
                />
              )}
            </div>
          </form>
        </div>
      </div>
      {/* Modal */}
      <Modal
        btnName="Add Another User"
        title="Member Added 🎉"
        message="Member added to the system successfully. Would you like to add another member?"
        navigation={navigation}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default AddDsMember;
