import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonGroup from '../../components/buttonGroup/ButtonGroup';
import DsMember from '../../components/table/DsMember';
import { useAuth } from '../../context/AuthContext';
import DefaultLayout from '../../layout/DefaultLayout';
import { calculateTotalFunding } from '../../utils/calculateTotalFunding';
import { calculateSpending } from '../../utils/fundingStatus';
import Loading from '../../widgets/loading/Loading';

const calculateBudgets = async (members) => {
  try {
    const memberWithCalculatedBudget = await Promise.all(
      members.map(async (member) => {
        try {
          let budgetStatus = 'No Data';

          // Check if member and required properties exist
          if (!member.details?.clientInfo) {
            return { ...member, budgetStatus };
          }

          // Check for inactive status first
          if (member.details.clientInfo.status === 'Inactive') {
            budgetStatus = 'Inactive';
          } else if (
            member.details.status === 'Active' &&
            member.details.planBudgets
          ) {
            const coreFundsTotal = calculateTotalFunding(
              member.details.planBudgets,
              'core budget'
            );

            const spending = calculateSpending(
              member.details.ndisPlanStartDate,
              member.details.ndisPlanEndDate,
              coreFundsTotal.totalBudget,
              coreFundsTotal.totalRemaining
            );

            // Determine budget status based on spending
            if (spending.overspending > 0) {
              budgetStatus = 'Overspending';
            } else if (spending.underspending > 0) {
              budgetStatus = 'Underspending';
            } else {
              budgetStatus = 'On Track';
            }
          }

          return { ...member, budgetStatus };
        } catch (error) {
          console.error(
            `Failed to process member ${member._id}: ${error.message}`
          );
          return { ...member, budgetStatus: 'Error' };
        }
      })
    );

    return memberWithCalculatedBudget;
  } catch (error) {
    console.error('Error processing members:', error.message);
    throw error; // Let the component handle the error
  }
};

const MembersList = ({ members, loading }) => {
  const navigate = useNavigate();
  const [memberWithCalculatedBudget, setMemberWithCalculatedBudget] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const loadMembersWithDetails = async () => {
      try {
        const data = await calculateBudgets(members);
        setMemberWithCalculatedBudget(data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    loadMembersWithDetails();
  }, [members]);

  const handleNavigation = () => {
    navigate('/members/add');
  };

  const userData = useAuth();

  if (isError) {
    return <p>Error loading members...</p>;
  }

  return (
    <DefaultLayout>
      {isLoading || loading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-2">
            <ButtonGroup navigation={handleNavigation} />
          </div>

          {memberWithCalculatedBudget?.length ? (
            <DsMember memberWithCalculatedBudget={memberWithCalculatedBudget} />
          ) : (
            <p style={{ marginTop: '2rem' }}>
              There are no members in the system
            </p>
          )}
        </>
      )}
    </DefaultLayout>
  );
};

export default MembersList;
