import React, { useContext, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BsCompass } from 'react-icons/bs';
import Datepicker from 'react-tailwindcss-datepicker';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../api/api';
import DataContext from '../../../../context/DataContext';
import PopUpError from '../../../../widgets/error/PopUpError';
import BreadCrumb from '../../../breadCrumb/BreadCrumb';
import Modal from '../../../modal/Modal';
import Input from '../../formElements/inputs/Input';
import SubmitButton from '../../formElements/submitButton/SubmitButton';

const DsSummaryForm = ({ close }) => {
  const { setSummaryList, summaryList } = useContext(DataContext);
  let [isOpen, setIsOpen] = useState(false);
  const [error, SetError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [aHours, setAHours] = useState('');
  const [bHours, setBHours] = useState('');
  const [cHours, setCHours] = useState('');
  const [dHours, setDHours] = useState('');

  const handleValueChange = (newValue) => {
    setDate(newValue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const weeklySummary = {
      weekEnding: date.startDate,
      communityA: aHours,
      communityB: bHours,
      communityC: cHours,
      communityD: dHours,
    };

    try {
      const response = await api.post('/sales/ds/summary', weeklySummary);

      if (response) {
        const updatedSummary = [...summaryList, response.data];
        setSummaryList(updatedSummary);
        toast.success('DS Weekly Summary Added', {
          position: 'bottom-left',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
        setIsOpen(true);
      }
      setAHours('');
      setBHours('');
      setCHours('');
      setDHours('');

      setDate({
        startDate: null,
        endDate: null,
      });
    } catch (error) {
      SetError(true);
      setErrorMessage(error.response.data);
    }
  };

  const handleClose = () => {
    close(null);

    setDate({
      startDate: null,
      endDate: null,
    });
  };

  return (
    <>
      <BreadCrumb
        currentPage="DS Weekly Summary"
        prevPage="Accounts"
        icon={<BsCompass />}
        navigation={handleClose}
      />
      <div className="mt-2">
        <ToastContainer />
        {error && <PopUpError message={errorMessage.message} />}
        <div className="w-full shadow-md rounded-md">
          <form action="" onSubmit={handleSubmit}>
            <div className="w-full h-14 bg-slate-200">
              <h3 className="text-xl p-4 font-bold text-slate-600">
                DS weekly summary
              </h3>
            </div>
            <div className="flex flex-col items-center">
              <div className="w-[95%] mt-3">
                <Datepicker
                  useRange={false}
                  asSingle={true}
                  value={date}
                  onChange={handleValueChange}
                  placeholder={'Week Ending'}
                />
              </div>
              {/* NDIS */}
              <Input
                id="commA"
                value={aHours}
                change={setAHours}
                placeholder="Community A Total Hours"
                type="number"
              />
              <Input
                id="commB"
                value={bHours}
                change={setBHours}
                placeholder="Community B Total Hours"
                type="number"
              />
              <Input
                id="commC"
                value={cHours}
                change={setCHours}
                placeholder="Community C Total Hours"
                type="number"
              />
              <Input
                id="commD"
                value={dHours}
                change={setDHours}
                placeholder="Community D Total Hours"
                type="number"
              />

              <SubmitButton
                btnName="Add Member"
                icon={<AiOutlineArrowRight />}
              />
            </div>
          </form>
        </div>
      </div>
      {/* Modal */}
      <Modal
        btnName="Add More Data!"
        title="Summary Added 🎉"
        message="Direct Supports Weekly Summary have been added. Would you like to add another summary?"
        navigation={handleClose}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default DsSummaryForm;
