import { createContext, useEffect, useState } from 'react';
import api from '../api/api';

export const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [summaryList, setSummaryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setLoading(true);
        // Add a delay before fetching the data
        const response = await new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              const res = await api.get('/sales/ds/summary');
              resolve(res);
            } catch (error) {
              reject(error);
            }
          }, 1500); // 2000 milliseconds = 2 seconds
        });
        setSummaryList(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          setError(error);
          setErrorMessage(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          console.log(`Error: ${error.message}`);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, []);

  return (
    <DataContext.Provider
      value={{
        summaryList,
        setSummaryList,
        error,
        errorMessage,
        loading,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;

// useEffect(() => {
//   const fetchSummary = async () => {
//     try {
//       setLoading(true);

//       const response = await api.get("/sales/ds/summary");
//       setSummaryList(response.data);
//     } catch (error) {
//       if (error.response) {
//         console.log(error.response.data);
//         setError(error);
//         setErrorMessage(error.response.data);
//         console.log(error.response.status);
//         console.log(error.response.headers);
//       } else {
//         console.log(`Error: ${error.message}`);
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   fetchSummary();
// }, []);
