import React, { useState } from 'react';
import UserSettingCard from '../../components/card/settingsCard/UserSettingCard';
import AddUser from '../../components/forms/addUser/AddUser';
import AllUser from '../../components/table/AllUser';
import DefaultLayout from '../../layout/DefaultLayout';

const Settings = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'addUser':
        return <AddUser setSelectedComponent={setSelectedComponent} />;
      case 'showUsers':
        return <AllUser setSelectedComponent={setSelectedComponent} />;
      default:
        return null;
        break;
    }
  };
  return (
    <DefaultLayout>
      {selectedComponent === null && (
        <UserSettingCard setSelectedComponent={setSelectedComponent} />
      )}
      {renderComponent()}
    </DefaultLayout>
  );
};

export default Settings;
