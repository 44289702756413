import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api';
import PopUpError from '../../../widgets/error/PopUpError';
import LoadingButton from '../formElements/loadingButton/LoadingButton';
import SubmitButton from '../formElements/submitButton/SubmitButton';
import TextArea from '../formElements/textArea/TextArea';

const ActionForm = ({
  id,
  singleMember,
  setIsActionUpdateModalOpen,
  setSingleMember,
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [actionDetails, setActionDetails] = useState('');

  const userData = JSON.parse(localStorage.getItem('user_data'));
  const user = userData?.user;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateMemberData = { id, actionDetails, userName: user.name };

    try {
      setLoading(true);
      const response = await api.put('/membersWithDetails', updateMemberData);
      console.log('Update response', response.data);

      if (response?.data) {
        // setSingleMember((prevMember) => ({
        //   ...prevMember,
        //   community: response.data.community,
        //   name: response.data.name,
        // }));

        await queryClient.invalidateQueries({
          queryKey: ['membersWithDetails'],
        });

        toast.success('User Updated Successfully', {
          position: 'bottom-left',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      }
    } catch (error) {
      toast.error(error.response, {
        position: 'bottom-left',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      console.log(error);
      setError(true);
      if (error.response) {
        setErrorMessage(error.response);
      } else {
        setErrorMessage({ data: { message: 'An error occurred' } });
      }
    } finally {
      setLoading(false);
      setIsActionUpdateModalOpen(false);
    }
  };

  return (
    <>
      <div className="mt-2">
        <ToastContainer />
        {error && errorMessage.data && (
          <PopUpError message={errorMessage.data.message} />
        )}

        <div className="w-full">
          <form action="" onSubmit={handleSubmit}>
            <div className="w-full h-14 bg-slate-200">
              <h3 className="text-xl p-4 font-bold text-slate-600">
                Action Details
              </h3>
            </div>
            <div className="flex flex-col items-center">
              <TextArea
                id="actionDetails"
                value={actionDetails}
                change={(value) => setActionDetails(value)}
                placeholder="What action was taken?"
              />

              {loading ? (
                <LoadingButton />
              ) : (
                <SubmitButton btnName="Submit" icon={<AiOutlineArrowRight />} />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ActionForm;
