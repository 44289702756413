import React, { useState } from 'react';
import { FaCompass } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';
import { IoClose, IoLogOut, IoMenu } from 'react-icons/io5';
import { MdDashboard, MdExtension } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const Sidebar = () => {
  const { userData, logout } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      {/* Mobile Menu Button */}
      <button
        className="fixed top-4 right-4 z-50 p-2 rounded-md bg-slate-950 text-white md:hidden"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        {isMobileMenuOpen ? <IoClose size={24} /> : <IoMenu size={24} />}
      </button>

      {/* Sidebar */}
      <aside
        className={`fixed top-0 left-0 h-screen w-64 flex-col justify-between bg-slate-950 shadow transition-transform duration-300 ease-in-out z-40
          ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'} 
          md:translate-x-0 md:relative md:flex`}
      >
        <div className="px-8">
          {/* Logo */}
          <div className="flex h-16 w-full items-center mt-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
              viewBox="0 0 400 200"
            >
              <path
                fill="#408049"
                fillOpacity=".6"
                d="M28.3 43.7c12.5.2 32.9.2 45.5 0 12.5-.1 2.2-.2-22.8-.2s-35.3.1-22.7.2m272.5 43c1.2.2 3 .2 4 0 .9-.3-.1-.5-2.3-.4-2.2 0-3 .2-1.7.4m12 0c1.2.2 3 .2 4 0 .9-.3-.1-.5-2.3-.4-2.2 0-3 .2-1.7.4"
              />
              <path
                fill="#408049"
                fillOpacity=".5"
                d="M5.5 89c0 25 .1 35.3.2 22.8.2-12.6.2-33 0-45.5-.1-12.6-.2-2.3-.2 22.7"
              />
              <path
                fill="#408049"
                d="M6 89v45h6V50h84v-6H6zm239.5-14.1c-8 3.6-13.5 12.3-13.5 21.1 0 4.2 3.8 12.7 7.1 15.7 7.9 7.2 21.4 6.7 28.7-1l2.2-2.3-2.4-2.3-2.4-2.3-4.5 2.6c-8.2 4.8-16.1 2.5-19.2-5.8-2.3-6-1.4-10.9 2.9-15.2 4.4-4.4 9.3-5.3 14.6-2.6l4 2 2-2.6c2.4-3 1.5-4.6-4.5-7.3-5.1-2.3-9.9-2.3-15 0m36.2 13.3c-4.1 2.4-7.7 8.7-7.7 13.4.1 8 6.8 15.4 14.2 15.4 3.9 0 8.8-1.8 8.8-3.2 0-.4.5-.8 1-.8.6 0 1 .9 1 2 0 1.6.7 2 3.5 2h3.5V87h-3.5c-2.4 0-3.5.5-3.5 1.5 0 1.9-.9 1.9-3.1-.1-3-2.7-9.8-2.8-14.2-.2M296 95c2.7 2.7 2.7 9.9.1 13.1-2.4 3-8.9 3.3-12 .5-2.9-2.6-3.6-7.7-1.7-11.4 1.8-3.5 2.8-4 7.9-4.1 2.4-.1 4.4.6 5.7 1.9m29-8.3c-.8.3-2.5 1.4-3.7 2.5-2.1 2-2.1 2-2.8-.1-.5-1.6-1.5-2.1-4.1-2.1H311v30h8v-9.9c0-9.6.1-10 2.6-12 1.6-1.3 4.1-2.1 6.4-2.1 3.4 0 3.8-.3 4.2-3 .3-1.7.2-3.2 0-3.5-.7-.6-5.3-.5-7.2.2m19.2.9c-4.5 2.3-8.3 7.5-8.9 12.2-1.1 8.2 5.7 16.1 14.6 17 4.9.4 12.1-1.2 12.1-2.8 0-3.2-1.2-3.8-7.3-3.3-5.3.4-6.6.2-8.9-1.7-1.6-1.2-2.8-2.6-2.8-3.1s4.9-.9 11-.9h11v-3.6c0-11-11.4-18.6-20.8-13.8m11.8 7.6c3.7 3.9 2.7 4.8-5.5 4.8s-9.4-1.1-5.5-5c2.7-2.7 8.3-2.7 11 .2"
              />
              <path
                fill="#408049"
                fillOpacity=".8"
                d="M96.2 47c0 1.9.2 2.7.5 1.7.2-.9.2-2.5 0-3.5-.3-.9-.5-.1-.5 1.8"
              />
              <path
                fill="#408049"
                fillOpacity=".9"
                d="M12.5 92c0 23.4.1 32.9.2 21.3.2-11.7.2-30.9 0-42.5-.1-11.7-.2-2.2-.2 21.2m334.3 7.7c2.3.2 5.9.2 8 0 2-.2.1-.4-4.3-.4s-6.1.2-3.7.4"
              />
              <path
                fill="#408049"
                fillOpacity=".7"
                d="M33.8 50.7c11.7.2 30.6.2 42 0 11.4-.1 1.8-.2-21.3-.2s-32.4.1-20.7.2M354.5 105l-11 .6 10.7.2c6.3.1 10.9-.2 11.3-.8.3-.6.5-.9.3-.8s-5.2.5-11.3.8M7.3 134.7c.9.2 2.5.2 3.5 0 .9-.3.1-.5-1.8-.5s-2.7.2-1.7.5"
              />
              <path
                fill="#00006D"
                fillOpacity=".1"
                d="m387.2 60.7 3.8.4.3 45.5.2 45.4V60.5l-4-.1-4-.1zm-66.4 84c11.7.2 30.6.2 42 0 11.4-.1 1.8-.2-21.3-.2s-32.4.1-20.7.2"
              />
              <path
                fill="#00006D"
                fillOpacity=".2"
                d="M383.5 102.5c0 23.1.1 32.4.2 20.7.2-11.7.2-30.6 0-42-.1-11.4-.2-1.8-.2 21.3m-154.2-12c0 2.2.2 3 .4 1.7.2-1.2.2-3 0-4-.3-.9-.5.1-.4 2.3m-23.9 17c0 5.5.1 7.6.3 4.7s.2-7.4 0-10-.3-.2-.3 5.3m23.6 6.2c-.3 2.3-.2 3.9.3 3.4s.7-2.4.5-4.2c-.3-3.3-.4-3.3-.8.8m70.4 35.3.1 3.5H391l-45.4-.2-45.5-.3-.4-3.3c-.4-3.2-.4-3.2-.3.3"
              />
              <path
                fill="#4000DB"
                d="M384 103v42h-84v7h91V61h-7zM34 95.6v21.7l11.3-.5c12.5-.5 16.1-1.7 20-7 5.5-7.5 6.3-17.3 2-25.9-4.2-8.2-7.9-9.9-22-9.9H34zm24.6-11.3c4.6 5.4 4.3 16.9-.6 21.9-2.6 2.5-3.5 2.8-9.4 2.8H42V80.8l7.1.4c6.3.3 7.4.7 9.5 3.1m154.4-.9c0 2.6-.5 3.6-2 4.1-1.3.4-2 1.5-2 3.1 0 1.7.5 2.4 2 2.4 1.9 0 2 .7 2 9.4 0 7.8.3 9.7 2 11.8 2.2 2.9 5.8 3.9 10.7 3.1 2.8-.5 3.3-1 3.3-3.4 0-2.5-.4-2.9-2.9-2.9-4.4 0-6.1-3.1-6.1-11.2V93h4.5c4.3 0 4.5-.1 4.5-3s-.2-3-4.5-3c-4.5 0-4.5 0-4.5-3.5 0-3.3-.2-3.5-3.5-3.5s-3.5.2-3.5 3.4M82.5 88.2c-5.1 2.9-9 10.2-8.1 15.1 1.5 8.1 8.5 13.7 17 13.7 4.5 0 9.6-1.6 9.6-3.1 0-3.1-1.3-3.6-7.3-3.2-5.8.5-6.4.3-9.2-2.3l-3-2.9L93 105l11.5-.5-.2-3.9c-.7-11.3-12.3-17.9-21.8-12.4M95 95c3.9 3.9 2.7 5-5.5 5s-9-.7-5.3-4.7c2.7-2.9 8-3.1 10.8-.3m24.5-7.6c-4.1 1.8-8.3 6.9-9.1 11.1-2.3 12.7 11.6 23 22.4 16.5 3.7-2.3 3.9-3.1 1.5-5.3-1.6-1.3-2.1-1.4-4.3-.2-3.5 2-6.3 1.8-9.4-.6-2.1-1.7-2.6-2.9-2.6-6.8 0-6.1 2.5-9.1 7.8-9.1 2.1 0 4.3.5 4.9 1.1.9.9 1.8.6 3.7-.8 2.4-1.9 2.4-2.1.8-3.6-4-3.7-10.5-4.6-15.7-2.3m31 0c-1.7.7-4.3 2.8-5.8 4.5-8.5 10.2-.8 25.1 13.1 25.1 4.1 0 10.2-1.7 10.2-2.8-.1-2.8-2.2-3.7-7.7-3.4-4.8.3-6.3 0-8.6-1.8-1.5-1.2-2.7-2.6-2.7-3.1s4.9-.9 11-.9h11v-3.6c0-5-3.5-10.7-8-13.3-4.3-2.4-8.1-2.6-12.5-.7m11.4 8.2c1.2 1.5 2.1 3.1 2.1 3.5 0 .5-3.4.9-7.5.9-8.2 0-9.4-1.1-5.5-5 3-3 8.3-2.7 10.9.6M178 102v15h7v-9.6c0-5.2.4-10.4 1-11.4 1.4-2.6 5.7-3.4 8.6-1.5 2.3 1.5 2.4 2.1 2.4 12V117h8v-10c0-11.7-1.4-16.1-5.9-18.4-3.5-1.9-6.9-2-10.7-.6-1.9.8-3 .8-3.8 0-.5-.5-2.3-1-3.8-1H178z"
              />
              <path
                fill="#004024"
                fillOpacity=".2"
                d="M310.4 102c0 8.5.2 12 .3 7.7.2-4.2.2-11.2 0-15.5-.1-4.2-.3-.7-.3 7.8"
              />
              <path
                fill="#004024"
                fillOpacity=".3"
                d="M306.4 102c0 8.5.2 12 .3 7.7.2-4.2.2-11.2 0-15.5-.1-4.2-.3-.7-.3 7.8m13 6.5c0 4.9.1 7.1.3 4.8s.2-6.4 0-9-.3-.8-.3 4.2"
              />
              <path
                fill="#00006D"
                fillOpacity=".3"
                d="M196.4 107.5c0 5.5.1 7.6.3 4.7s.2-7.4 0-10-.3-.2-.3 5.3"
              />
              <path
                fill="#4000DB"
                fillOpacity=".7"
                d="M38.8 73.7c2.9.2 7.4.2 10 0s.2-.3-5.3-.3-7.6.1-4.7.3m6.5 8c1.5.2 3.7.2 5 0 1.2-.2 0-.4-2.8-.4-2.7 0-3.8.2-2.2.4M177.4 102c0 8.5.2 12 .3 7.7.2-4.2.2-11.2 0-15.5-.1-4.2-.3-.7-.3 7.8m8 5.5c0 5.5.1 7.6.3 4.7s.2-7.4 0-10-.3-.2-.3 5.3m-97.1-1.8c3.2.2 8.1.2 11 0s.3-.3-5.8-.3c-6 0-8.4.1-5.2.3m67 0c3.2.2 8.1.2 11 0s.3-.3-5.8-.3c-6 0-8.4.1-5.2.3"
              />
              <path
                fill="#4000DB"
                fillOpacity=".6"
                d="M179.3 86.7c.9.2 2.5.2 3.5 0 .9-.3.1-.5-1.8-.5s-2.7.2-1.7.5m43.5 0c1.2.2 3.2.2 4.5 0 1.2-.2.2-.4-2.3-.4s-3.5.2-2.2.4"
              />
              <path
                fill="#4000DB"
                fillOpacity=".5"
                d="M212.2 83c0 1.9.2 2.7.5 1.7.2-.9.2-2.5 0-3.5-.3-.9-.5-.1-.5 1.8m.1 18.5c0 4.4.2 6.1.4 3.7.2-2.3.2-5.9 0-8-.2-2-.4-.1-.4 4.3"
              />
              <path
                fill="#00006D"
                fillOpacity=".4"
                d="M33.4 95.5c0 12.1.2 16.9.3 10.7.2-6.2.2-16.1 0-22-.1-5.9-.3-.8-.3 11.3m181.4-15.8c1.2.2 3.2.2 4.5 0 1.2-.2.2-.4-2.3-.4s-3.5.2-2.2.4m-169.5 29c1.5.2 3.7.2 5 0 1.2-.2 0-.4-2.8-.4-2.7 0-3.8.2-2.2.4"
              />
              <path
                fill="#4000DB"
                fillOpacity=".9"
                d="M220.3 83.5c0 2.2.2 3 .4 1.7.2-1.2.2-3 0-4-.3-.9-.5.1-.4 2.3M42.4 95c0 8 .2 11.2.3 7.2.2-3.9.2-10.5 0-14.5-.1-3.9-.3-.7-.3 7.3m165.7-3.8c-.1 2.4.3 2.8 2.7 2.7 2.2-.1 2.3-.2.5-.6-1.3-.2-2.4-1.3-2.6-2.6-.4-1.8-.5-1.7-.6.5m12.1 9 .2 7.3.3-6.7.4-6.7 4.2-.4 4.2-.4-4.7-.1-4.8-.2zm-134.4-.5c2.3.2 5.9.2 8 0 2-.2.1-.4-4.3-.4s-6.1.2-3.7.4m67 0c2.3.2 5.9.2 8 0 2-.2.1-.4-4.3-.4s-6.1.2-3.7.4"
              />
            </svg>
          </div>
          {/* nav Items */}
          <ul className="mt-12">
            {/* Logo */}
            <li className="mb-6 flex w-full cursor-pointer items-center justify-between text-gray-300">
              <NavLink
                to="/"
                className="flex items-center focus:outline-none focus:ring-2 focus:ring-white"
              >
                <MdDashboard />
                <span className="ml-2 text-sm">Dashboard</span>
              </NavLink>
            </li>
            {/* two */}
            <li className="mb-6 flex w-full cursor-pointer items-center justify-between text-gray-400 hover:text-gray-300">
              <NavLink
                to="/members"
                className="flex items-center focus:outline-none focus:ring-2 focus:ring-white"
              >
                <MdExtension />
                <span className="ml-2 text-sm">DS Members</span>
              </NavLink>
            </li>
            {/* accounts */}

            {(userData.position === 'cfo' || userData.role === 'admin') && (
              <li className="mb-6 flex w-full cursor-pointer items-center justify-between text-gray-400 hover:text-gray-300">
                <NavLink
                  to="/accounts"
                  className="flex items-center focus:outline-none focus:ring-2 focus:ring-white"
                >
                  <FaCompass />
                  <span className="ml-2 text-sm">Accounts</span>
                </NavLink>
              </li>
            )}
            {/* four */}
            {/* <li className="mb-6 flex w-full cursor-pointer items-center justify-between text-gray-400 hover:text-gray-300">
              <a
                href="javascript:void(0)"
                className="flex items-center focus:outline-none focus:ring-2 focus:ring-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-code"
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="7 8 3 12 7 16" />
                  <polyline points="17 8 21 12 17 16" />
                  <line x1={14} y1={4} x2={10} y2={20} />
                </svg>
                <span className="ml-2 text-sm">Deliverables</span>
              </a>
            </li> */}
            {/* five */}
            {/* <li className=mb-6 flex w-full cursor-pointer items-center justify-between text-gray-400 hover:text-gray-300">
              <a
                href="javascript:void(0)"
                className=flex items-center focus:outline-none focus:ring-2 focus:ring-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className=icon icon-tabler icon-tabler-puzzle"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z"></path>
                  <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1"></path>
                </svg>
                <span className=ml-2 text-sm">Invoices</span>
              </a>
            </li> */}
            {/* six */}
            {/* <li className=mb-6 flex w-full cursor-pointer items-center justify-between text-gray-400 hover:text-gray-300">
              <a
                href="javascript:void(0)"
                className=flex items-center focus:outline-none focus:ring-2 focus:ring-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className=icon icon-tabler icon-tabler-stack"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="12 4 4 8 12 12 20 8 12 4" />
                  <polyline points="4 12 12 16 20 12" />
                  <polyline points="4 16 12 20 20 16" />
                </svg>
                <span className=ml-2 text-sm">Inventory</span>
              </a>
            </li> */}
            {/* Settings */}
            {userData.role === 'admin' && (
              <li className="flex w-full cursor-pointer items-center justify-between text-gray-400 hover:text-gray-300">
                <NavLink
                  className="flex items-center focus:outline-none focus:ring-2 focus:ring-white"
                  to="/settings"
                >
                  <IoMdSettings />
                  <span className="ml-2 text-sm">Settings</span>
                </NavLink>
              </li>
            )}
            {/* eight */}
          </ul>
        </div>
        {/* logout */}
        <div className="border-y border-gray-700 w-full bg-slate-900 mt-5">
          <div className="flex mx-auto h-14 w-28 p-2 mb-1">
            <div className="flex w-full cursor-pointer items-center justify-between text-gray-400 hover:text-gray-300">
              <div
                role="button"
                onClick={handleLogout}
                className="flex items-center focus:outline-none focus:ring-2 focus:ring-white"
              >
                <IoLogOut size={25} style={{ color: '#ffffff ' }} />
                <span className="ml-2 text-md">Logout</span>
              </div>
            </div>
          </div>
        </div>
      </aside>

      {/* Overlay for mobile */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </>
  );
};

export default Sidebar;
